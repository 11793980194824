<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 14:02:22
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-09-20 11:48:00
-->
<template>
    <div :class="['setting-user', $i18n.locale]">
        <div class="header-menu">
            <span>
                <img
                    src="@/assets/images/sidebar/arrow-left.png"
                    alt
                    class="menu-info"
                    @click.prevent="onClickLeft()"
                />
            </span>
        </div>
        <!-- <van-nav-bar :left-text="$t('sidebar.setting')" left-arrow @click-left="onClickLeft()" /> -->
        <div class="content">
            <div class="title">{{ $t('sidebar.personal') }}</div>
            <div class="group">
                <van-cell
                    :title="mobile ? $t('sidebar.form.labelList[0]') : $t('login.email')"
                    :value="iphone || email"
                />
            </div>
            <van-cell
                v-for="(item, index) in cellList"
                :key="item.label"
                :title="item.label"
                :value="item.unit && item.value.indexOf('ft') === -1 ? `${item.value} ${item.unit}` : item.value"
                is-link
                :class="index == 0 ? 'free-style' : ''"
                @click="onCellClick(item)"
            />
            <div class="botton">
                <van-button
                    color="linear-gradient(90deg, #00E3C9 0%, #009FE8 100%)"
                    style="border-radius: 6px"
                    block
                    @click="onSubmit"
                >
                    {{ $t('sidebar.logOut') }}
                </van-button>
            </div>
        </div>
        <van-popup v-model="isShowPopup" position="bottom" :style="{ height: '35%' }">
            <div class="btns">
                <span class="cancel" @click="cancelBtn()">{{ $t('sidebar.btn.cancelBtn') }}</span>
                <span @click="onComplete">{{ $t('sidebar.btn.accomplishBtn') }}</span>
            </div>
            <div class="content">
                <div class="text" v-if="title">{{ title }}</div>
                <div class="username" v-if="type === 'username'">
                    <van-field v-model="username" maxlength="100" label="" :placeholder="$t('register.userMessage')" />
                </div>
                <div class="sex" v-else-if="type === 'sex'">
                    <div class="sex-warpper" v-for="item in sexlist" :key="item.value">
                        <div :class="['man', item.value === sex ? 'active' : '']" @click="sex = item.value">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
                <div class="height" v-else-if="type === 'height' && this.heightType === 'metric'">
                    <div class="field">
                        <div class="warrper">
                            <div
                                type="default"
                                class="increment"
                                @click="height = Number(height) - 1 < 0 ? height : Number(height) - 1"
                            >
                                -
                            </div>
                        </div>
                        <div class="height-field">
                            <van-field v-model="height" type="number" label="" class="input" />
                            <span class="ext">{{ $i18n.locale==='ar-AR'? 'سم'  : 'cm'}}</span>
                        </div>
                        <div class="warrper">
                            <div type="default" class="decrement" @click="height = Number(height) + 1">+</div>
                        </div>
                    </div>
                </div>
                <div class="height" v-else-if="type === 'height' && this.heightType === 'imperial'">
                    <div class="field ft-in">
                        <div class="height-field">
                            <div class="ft">
                                <van-field v-model="usrForm.ft" type="number" label="" class="input" />
                                <div class="ext">{{$i18n.locale==='ar-AR'? 'قدم'  : 'ft'}}</div>
                            </div>
                            <div class="in">
                                <van-field v-model="usrForm.in" type="number" label="" class="input" />
                                <div class="ext">{{$i18n.locale==='ar-AR'? 'بوصة'  : 'in'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- -->
                <div class="age" v-else-if="type === 'age'">
                    <div class="field">
                        <div class="warrper">
                            <div
                                type="default"
                                class="increment"
                                @click="age = Number(age) - 1 < 0 ? age : Number(age) - 1"
                            >
                                -
                            </div>
                        </div>
                        <div class="height-field">
                            <van-field v-model="age" type="number" label="" class="input" />
                            <span class="ext">{{ $t('register.unit[2]') }}</span>
                        </div>
                        <div class="warrper">
                            <div type="default" class="decrement" @click="age = Number(age) + 1">+</div>
                        </div>
                    </div>
                </div>
                <div class="error-tip" v-if="errorMg">
                    {{ errorMg }}
                </div>
            </div>
        </van-popup>
        <van-dialog v-model="isConfirm" :show-confirm-button="false" @close="reset">
            <template slot="title"> {{ dialogTitle }} </template>
            <p>{{ confirmDesc }}</p>
            <div class="btns">
                <div class="warrper1">
                    <van-button plain @click="onCancel" class="dialog-cancel">{{
                        $t('sidebar.btn.cancelBtn')
                    }}</van-button>
                </div>
                <div class="warrper2">
                    <van-button
                        @click="onConfirm"
                        class="dialog-confirm"
                        color="linear-gradient(90deg, #00E3C9 0%, #009FE8 100%)"
                        >{{ $t('sidebar.btn.confirmBtn') }}</van-button
                    >
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { findCurrentMember, updateMember } from '@/assets/js/apolloGql.js'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            iphone: '',
            usrForm: {
                ft: '',
                in: '',
            },
            heightType: '',
            errorMg: '',
            dialogTitle: '',
            confirmDescList: [
                {
                    type: 'sex',
                    title: this.$t('sidebar.placeholder[1]'),
                    desc: this.$t('sidebar.dialog[0]'),
                },
                {
                    type: 'height',
                    title: this.$t('sidebar.placeholder[2]'),
                    desc: this.$t('sidebar.dialog[1]'),
                },
                {
                    type: 'age',
                    title: this.$t('sidebar.placeholder[3]'),
                    desc: this.$t('sidebar.dialog[2]'),
                },
            ],
            confirmDesc: '',
            confirmText: '',
            isConfirm: false,
            sex: null,
            height: null,
            username: '',
            age: null,
            code: '',
            sexlist: [
                {
                    label: this.$t('register.sexOptions.male'),
                    value: 1,
                },
                {
                    label: this.$t('register.sexOptions.female'),
                    value: 2,
                },
            ],
            type: '',
            title: this.$t('sidebar.placeholder[0]'),
            isShowPopup: false,
            cellList: [
                {
                    label: this.$t('sidebar.form.labelList[3]'),
                    value: '',
                    type: 'username',
                    title: this.$t('sidebar.placeholder[0]'),
                },
                {
                    label: this.$t('sidebar.form.labelList[4]'),
                    value: '',
                    type: 'sex',
                    title: '',
                },
                {
                    label: this.$t('sidebar.form.labelList[5]'),
                    value: '',
                    unit: 'cm',
                    type: 'height',
                    title: this.$t('sidebar.heightMsg'),
                },
                {
                    label: this.$t('sidebar.form.labelList[6]'),
                    value: '',
                    unit: this.$t('register.unit[2]'),
                    type: 'age',
                    title: this.$t('sidebar.ageMsg'),
                },
            ],
            mobile: '',
            email: window.localStorage.getItem('email'),
        }
    },
    created() {
        // this.heightType = this.$store.state.unit
        this.heightType = localStorage.getItem('unit')
        this.iphone = this.fomatterIphone(this.iphone)
    },
    computed: {
        ...mapState(['unit']),
    },
    mounted() {
        if(this.$i18n.locale==='ar-AR'){
            this.cellList[2].unit='سم'
        }
        this.findCurrentMember()
    },
    methods: {
        fomatterIphone(str) {
            // const reg = /(\d{3})\d*(\d{4})/
            // const result = str.replace(reg, '$1****$2')
            // return result
            let result = str || ''
            const phoneLen = result.length
            if (phoneLen > 14) {
                result = result.substr(0, 7) + '****' + result.substr(phoneLen - 4)
            } else if (phoneLen === 14) {
                result = result.substr(0, 6) + '****' + result.substr(phoneLen - 4)
            } else if (phoneLen > 7) {
                result = result.substr(0, 5) + '*'.repeat(phoneLen - 7) + result.substr(phoneLen - 2)
            }
            return result
        },
        validteAge() {
            if (this.age === '') {
                this.errorMg = this.$t('sidebar.rules.ageMsg[0]')
            } else {
                if (this.age >= 10 && this.age <= 99) {
                    if (this.age.indexOf('.')) {
                        this.errorMg = this.$t('sidebar.rules.ageMsg[0]')
                    }
                    if (/^\d+$/.test(this.age)) {
                        return true
                    } else {
                        // this.errorMg = this.$t('sidebar.rules.ageMsg[0]')
                        this.errorMg = this.$t('register.rules.agePointMsg[0]')
                    }
                } else {
                    this.errorMg = this.$t('sidebar.rules.ageMsg[0]')
                }
            }
        },
        // 最终身高
        finalHeight() {
            if (this.heightType === 'imperial') {
                if (this.usrForm.ft || this.usrForm.in) {
                    this.height = parseInt(this.usrForm.ft * 12, 10) + parseInt(this.usrForm.in, 10)
                }
            }
        },
        // 校验身高
        valideHeight(unit) {
            console.log(unit)
            if (unit === 'imperial') {
                this.finalHeight()
                const ft = this.usrForm.ft
                const _in = this.usrForm.in
                const ftRegName = /^[2-6]$/
                const inRegName = /^([0-9]|1[0-1])$/
                const ruleErrMsg = this.$t('sidebar.rules.metricheight[0]')
                const cunErrMsg = this.$t('sidebar.rules.metricheight[0]')
                if (!ft && _in) {
                    // 英寸
                    this.errorMg = cunErrMsg
                } else if (ft && !_in) {
                    // 英尺
                    this.errorMg = cunErrMsg
                } else if (ft && _in) {
                    console.log(!ftRegName.test(ft), !inRegName.test(_in))
                    // 英尺+英寸
                    if (!ftRegName.test(ft) || !inRegName.test(_in) || this.height < 27 || this.height > 79) {
                        this.errorMg = ruleErrMsg
                    } else {
                        return true
                    }
                } else {
                    // 都未填写
                    this.errorMg = cunErrMsg
                }
            } else if (unit === 'metric') {
                // 身高校验规则
                // const regex = /^(1[1-9]\d(\.\d+)?|20[0-4](\.\d+)?|205)$/
                if (/^\d+$/.test(this.height) || this.height < 0 || this.height === '') {
                    if (this.height < 70 || this.height > 200) {
                        this.errorMg = this.$t('sidebar.rules.heightMsg[0]')
                    } else {
                        return true
                    }
                } else {
                    // this.errorMg = '允许输入的身高格式不支持小数，请重新输入'
                    this.errorMg = this.$t('register.errorMsg')
                }
            }
        },
        validateField() {
            let valid = false
            if (this.type === 'height' && this.valideHeight(this.heightType)) {
                this.$toast({
                    message: this.$t('sidebar.successMsg.height'),
                    duration: 2000,
                })
                valid = true
            } else if (this.type === 'age' && this.validteAge()) {
                this.$toast({
                    message: this.$t('sidebar.successMsg.age'),
                    duration: 2000,
                })
                valid = true
            } else if (['sex'].includes(this.type)) {
                this.$toast({
                    message: this.$t('sidebar.successMsg.sex'),
                    duration: 2000,
                })
                valid = true
            } else if (['username'].includes(this.type)) {
                valid = true
            }
            return valid
        },
        isShowDialog(type) {
            let value = ''
            return this.cellList.some((item) => {
                if (type === 'sex') {
                    value = item.value === this.$t('register.sexOptions.male') ? 1 : 2
                } else {
                    value = item.value
                }
                if (
                    item.type === 'height' &&
                    this.heightType === 'imperial' &&
                    value !== `${this.usrForm.ft}ft,${this.usrForm.in}in`
                ) {
                    return true
                }
                if (item.type === type && value !== this[type]) {
                    return true
                }
            })
        },
        onConfirm() {
            this.isShowPopup = true
            this.isConfirm = false
        },
        onCancel() {
            this.isConfirm = false
        },
        cancelBtn() {
            this.isShowPopup = false
            this.cellList.forEach((item) => {
                if (item.type === 'height') {
                    if (this.heightType === 'imperial') {
                        this.usrForm.ft = item.value.split(',')[0].split('ft')[0]
                        this.usrForm.in = item.value.split(',')[1].split('in')[0]
                    } else {
                        this.height = item.value
                    }
                } else if (item.type === 'age') {
                    this.age = item.value
                }
            })
        },
        transformData() {
            this.cellList.forEach((item) => {
                if (item.type === 'sex' && item.type === this.type) {
                    item.value =
                        this[item.type] === 1
                            ? this.$t('register.sexOptions.male')
                            : this.$t('register.sexOptions.female')
                } else if (item.type === 'height' && item.type === this.type && this.heightType === 'imperial') {
                    item.value = `${this.usrForm.ft}ft,${this.usrForm.in}in`
                } else if (item.type === this.type) {
                    item.value = this[item.type]
                }
                this.isShowPopup = false
            })
            this.updateMember()
            this.reset()
        },
        onComplete() {
            const valid = this.validateField()
            if (!valid) {
                return
            }
            this.transformData()
        },
        reset() {
            this.cellList.forEach((item) => {
                if (item.type === 'height') {
                    if (this.heightType === 'imperial') {
                        this.usrForm.ft = item.value.split(',')[0].split('ft')[0]
                        this.usrForm.in = item.value.split(',')[1].split('in')[0]
                    } else {
                        this.height = item.value
                    }
                } else if (item.type === 'age') {
                    this.age = item.value
                } else if (item.type === 'username') {
                    this.username = item.value
                } else if (item.type === 'sex') {
                    if (item.value !== this.$t('register.sexOptions.male')) {
                        this.sex = 2
                    } else {
                        this.sex = 1
                    }
                }
            })
            this.errorMg = ''
        },
        onCellClick(row) {
            this.errorMg = ''
            if (row.type === 'sex') {
                this[row.type] = row.value === this.$t('register.sexOptions.male') ? 1 : 2
            } else if (row.type === 'height' && this.heightType === 'imperial') {
                this.usrForm.ft = row.value.split('ft')[0]
                this.usrForm.in = row.value.split(',')[1].split('in')[0]
            } else {
                this[row.type] = row.value
            }

            if (['sex', 'height', 'age'].includes(row.type)) {
                const confirm = this.confirmDescList.find((item) => item.type === row.type)
                this.confirmDesc = confirm.desc
                this.dialogTitle = confirm.title
            }

            this.type = row.type
            this.title = row.title
            if (['sex', 'height', 'age'].includes(this.type)) {
                this.isConfirm = true
            } else {
                this.isShowPopup = true
            }
        },
        onClickLeft() {
            this.$router.push({
                name: 'report',
                params: { type: 1 },
            })
        },
        // 侧边栏 获取用户信息
        findCurrentMember() {
            this.$apollo
                .query({
                    query: findCurrentMember,
                    fetchPolicy: 'network-only',
                })
                .then((res) => {
                    const data = res.data.findCurrentMember
                    if (data && data.code === 200) {
                        this.iphone = this.fomatterIphone(data.data.mobile)
                        this.mobile = data.data.mobile
                        this.id = data.data.id
                        this.cellList = this.cellList.map((item) => {
                            if (item.type === 'username') {
                                return {
                                    ...item,
                                    value: data.data.name,
                                }
                            } else if (item.type === 'height') {
                                if (this.heightType === 'metric') {
                                    return {
                                        ...item,
                                        value: Math.round(data.data.height * 2.54) + '',
                                    }
                                } else {
                                    const _ft = Math.floor(Math.round(data.data.height) / 12)
                                    const _in = Math.floor(Math.round(data.data.height) % 12)
                                    if(this.$i18n.locale==='ar-AR'){
                                        return {
                                        ...item,
                                        value: `${_ft}قدم,${_in}`,
                                    }  
                                    }
                                    return {
                                        ...item,
                                        value: `${_ft}ft,${_in}in`,
                                    }
                                }
                            } else if (item.type === 'age') {
                                return {
                                    ...item,
                                    value: data.data.age + '',
                                }
                            } else if (item.type === 'sex') {
                                return {
                                    ...item,
                                    value:
                                        data.data.sex === 1
                                            ? this.$t('register.sexOptions.male')
                                            : this.$t('register.sexOptions.female'),
                                }
                            }
                        })
                        console.log(this.cellList)
                    }
                })
        },
        findValue(type) {
            return this.cellList.find((item) => item.type === type)?.value
        },
        // 修改用户信息
        updateMember() {
            let height = ''
            if (this.usrForm.ft && this.usrForm.in && this.heightType === 'imperial') {
                height = parseInt(this.usrForm.ft * 12, 10) + parseInt(this.usrForm.in, 10)
            } else if (this.height && this.heightType === 'metric') {
                height = Number((this.height / 2.54).toFixed(1))
            } else {
                if (this.heightType === 'imperial') {
                    const oldHeight = this.findValue('height')
                    const _ft = oldHeight.split(',')[0]
                    const _in = oldHeight.split(',')[1]

                    height = parseInt(_ft.replace('ft', '') * 12, 10) + parseInt(_in.replace('in', ''), 10)
                } else {
                    // height = this.findValue('height')
                    height = Number((this.findValue('height') / 2.54).toFixed(1))
                }
            }
            const param = {
                mobile: this.mobile,
                name: this.username ? this.username : this.findValue('username'),
                sex: this.sex,
                height: height,
                age: this.age ? parseInt(this.age, 10) : parseInt(this.findValue('age')),
            }
            console.log(param)
            this.$apollo
                .mutate({
                    mutation: updateMember,
                    variables: {
                        member: param,
                    },
                })
                .then((res) => {
                    console.log(res)
                    const data = res.data.updateMember
                    if (data.code === 200) {
                        this.findCurrentMember()
                    }
                })
        },
        onSubmit() {
            window.localStorage.removeItem('modelInfo')
            window.localStorage.removeItem('memberInfo')
            window.localStorage.removeItem('emailHistory')
            window.sessionStorage.removeItem('token')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('loginInfo')
            this.$router.push({
                path: '/login',
            })
            location.reload()
        },
    },
}
</script>

<style lang="less" scoped>
.setting-user {
    width: 100%;
    height: 100%;
    background-color: #222341;
    text-align: left;
    .content {
        margin-top: 0px;
        .free-style {
            .van-cell__title {
                position: absolute;
            }
            .van-cell__value {
                span {
                    display: inline-block;
                    right: 0;
                    text-align: right;
                    max-width: 245px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .header-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        width: 100%;
        top: 0;
        z-index: 100;
        > span {
            img {
                display: block;
            }
        }
        .menu-info {
            width: 24px;
            height: 24px;
        }
    }
    .warrper {
        border: none;
        box-sizing: border-box;
        padding: 1px;
        border-radius: 5px;
        background-image: -webkit-linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1));
        .increment,
        .decrement {
            width: 32px;
            height: 32px;
            border-radius: 6px !important;
            border: 1px solid transparent;
            background-color: #27284c;
            color: #fff;
            text-align: center;
            line-height: 32px;
        }
    }
    .warrper1 {
        // box-sizing: content-box;
        padding: 1px;
        border-radius: 5px;
        background-image: -webkit-linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1));
        width: 74px;
        margin-right: 15px;
        overflow: hidden;
    }
    .warrper2 {
        box-sizing: content-box;
        padding: 1px;
        border-radius: 5px;
        width: 72px;
        /deep/ .dialog-confirm {
            height: 30px;
            width: 74px;
        }
        // background-image: -webkit-linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1));
    }
    .botton {
        margin-top: 180px;
    }
    /deep/ .van-dialog {
        width: 290px;
        background-color: #e7e7e7;
        padding: 20px;
        padding-top: 0;
        p {
            margin-bottom: 15px;
            text-align: center;
            font-size: 14px;
            margin: 0 10 px;
        }
        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .dialog-cancel {
                width: 72px;
                height: 30px;
                border-radius: 5px !important;
                // border: 1px solid;
                color: #000;
                background-color: #e7e7e7;
                margin-right: 15px;
                // border-image: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1)) 1 1;
            }
            .dialog-confirm {
                color: #fff;
                width: 72px;
                height: 30px;
                background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                border-radius: 5px !important;
                border: 1px solid transparent;
                // border-image: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1)) 1 1;
            }
        }
    }
    /deep/ .van-popup {
        background-color: #27284c;
        .btns {
            display: flex;
            align-items: center;
            padding: 0 20px;
            justify-content: space-between;
            height: 42px;
            background-color: #313261;
            span {
                font-size: 14px;
                color: #fff;
            }
        }
        .content {
            padding: 0 20px;
            height: calc(100% - 62px);
            .text {
                font-size: 14px;
                margin-top: 20px;
                text-align: center;
                color: #888888;
            }
            .van-cell {
                margin-top: 15px;
                border-radius: unset;
                background-color: #27284c;
                border-bottom: 1px solid #9293a5;
                .van-field__control {
                    color: #fff;
                }
            }
            .sex {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .sex-warpper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 76px;
                    height: 38px;
                    padding: 1px;
                    border-radius: 5px;
                    margin-right: 20px;
                    background-image: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1));
                }
                .man {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 80px;
                    width: 74px;
                    height: 36px;
                    background: #282b41;
                    border-radius: 4px;
                    color: #fff;
                }
                .active {
                    background-image: linear-gradient(90deg, rgba(0, 227, 201, 1), rgba(0, 159, 232, 1));
                }
            }
            .height,
            .age {
                .input {
                    margin-top: 0;
                    width: 60px !important;
                    padding: 0 !important;
                    border-bottom: unset !important;
                    &::after {
                        width: 80px;
                        left: 0;
                    }
                    .van-field__control {
                        text-align: center;
                    }
                }
                .field {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 40px;
                    margin-top: 30px;

                    .height-field {
                        display: flex;
                        .ft {
                            display: flex;
                        }
                        .in {
                            display: flex;
                        }
                        .ext {
                            margin-left: 5px;
                            padding-top: 5px;
                            color: #fff;
                        }
                    }
                }
                .ft-in {
                    display: flex;
                    justify-content: center;
                    .ft {
                        margin-right: 10px;
                    }

                    .input {
                        width: 40px !important;
                    }
                }
            }
            .error-tip {
                text-align: center;
                margin-top: 15px;
                font-size: 14px;
                color: #d0021b;
            }
        }
    }
    // .van-nav-bar {
    //     background-color: #222341;
    //     &::after {
    //         border-bottom: 1px solid #8f909e;
    //     }
    //     /deep/ .van-icon-arrow-left {
    //         // color: #fff;
    //         color: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
    //     }
    //     /deep/ .van-nav-bar__text {
    //         color: #fff;
    //     }
    // }
    .van-button__text {
        width: 200px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #222222;
        line-height: 22px;
    }
    .content {
        padding: 0 15px;
        .title {
            padding-left: 16px;
            padding-top: 16px;
            padding-bottom: 12px;
            color: #8f909e;
        }
        .van-cell {
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            // background-color: #232643;
            background-color: #303c5c;
            &::after {
                height: 1px;
                opacity: 0.1;
                border: 1px solid #ffffff;
            }
        }
        /deep/ .van-cell__title {
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
        }
        .group {
            margin-bottom: 10px;
        }
    }
    &.fr-FR,
    &.ja-JP {
        /deep/ .van-dialog {
            .btns {
                /deep/ .dialog-cancel {
                    width: 108px !important;
                }
                .dialog-confirm {
                    width: 90px;
                    border-radius: 4px !important;
                }
                /deep/ .van-button--plain {
                    background-color: #e7e7e7 !important;
                }
                .van-button__content {
                    width: 100%;
                }
                .van-button--normal {
                    padding: 0;
                }
            }
        }
        /deep/ .van-button__text {
            width: 200px;
        }
        .error-tip {
            width: 260px;
            margin: 0 auto;
            text-align: center;
        }
    }
}

.ru-RU {
    .warrper2 {
        button {
            width: 100px !important;
            background-color: #009fe8;
        }
    }
}
.ja-JP {
    .warrper1 {
        width: 104px;
        overflow: hidden;
        // text-align: center;
        button {
            width: 100px !important;
        }
    }
}
.id-ID,
.pt-PTO{
    .warrper2 {
        width: 100px;
        /deep/ .dialog-confirm {
            width: 100px !important;
        }
    }
    .warrper1 {
        width: 100px;
        /deep/ .dialog-confirm {
            width: 100px !important;
        }
        /deep/ .dialog-cancel {
            width: 98px !important;
        }
    }
}
&.de-DE,
.el-GR  {
    .warrper1 {
        width: 112px;
        overflow: hidden;
        button {
            font-weight: 400;
            width: 110px !important;
        }
    }
    .warrper2 {
        width: 112px;
        overflow: hidden;
        button {
            font-weight: 400;
            width: 110px !important;
        }
    }
}
.en-US {
    .warrper1 {
        width: 112px;
        overflow: hidden;
        button {
            font-weight: 400;
            width: 110px !important;
        }
    }
    .warrper2 {
        width: 112px;
        overflow: hidden;
        button {
            font-weight: 400;
            width: 110px !important;
        }
    }
    /deep/ .van-dialog {
        .btns {
            .cancel {
                color: #8aa3be !important;
            }
        }
        p {
            width: 238px;
            text-align: left;
            line-height: 20px;
            margin: auto;
            padding-bottom: 10px;
        }
    }
    .van-button__text {
        font-weight: 400;
    }
    .active {
        color: #222222 !important;
    }
}
.ar-AR {
    direction: rtl;
    .group{
        .van-cell__value {
        text-align: left;
        direction: ltr;
    }  
    }
    .van-cell__value {
        text-align: left;

    }
    .van-cell__title {
        text-align: right;
    }
    .title {
        text-align: right;
    }
    .warrper2 {
        width: 100px;
    }
    .dialog-cancel {
        width: 72px !important;
        margin-right: 0 !important;
    }
    .van-dialog p {
        text-align: right;
    }
    .van-cell {
        /deep/.van-field__control {
            text-align: right;
        }
    }
    /deep/ .van-cell__right-icon{
        transform: scaleX(-1);
        margin-left: 0;
        margin-right: 10px;
    }
     .menu-info{
        -webkit-transform: scaleX(-1);
     }
}
</style>
<style>
.van-cell__value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
